<template>
  <div>
    <div class="block">
      <span class="demonstration">多个日期</span>
      <el-date-picker
        v-model="filter.date"
        type="dates"
        placeholder="选择一个或多个日期">
      </el-date-picker>
      <vm-search label="时间范围">
        <el-date-picker
          v-model="filter.seTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="handleTime">
        </el-date-picker>
      </vm-search>
      <vm-search label="班组名称">
        <el-input v-model="filter.teamName" type="iotDeviceModel"></el-input>
      </vm-search>
      <vm-search label="楼盘名称">
        <el-input v-model.trim="filter.realEstateName" clearable></el-input>
      </vm-search>
      <vm-search label="故障类型">
        <el-input v-model.trim="filter.faultName" clearable></el-input>
      </vm-search>

      <el-button type="primary" style="margin-bottom: 5px;margin-left: 5px" @click="fetch">搜索</el-button>
      <el-button type="primary" style="margin-bottom: 5px;margin-left: 5px" @click="refetch">重置</el-button>
      <el-button type="primary" style="margin-bottom: 5px;margin-left: 5px" @click="exportExcelData">导出</el-button>
    </div>

    <div class="box">
      日期
      <div style="width: 700px;height: 300px" class="content">
        <div v-for="(item,index) in dateOrder" :key="index" style="height: 50px">
          <div style="border: 1px solid black;border-bottom: none">
            {{item.dateTime}}
          </div>
          <div style="border: 1px solid black;text-align: center">
            {{item.orderNumber}}
          </div>
        </div>
      </div>
      <div id="date" style="width: 900px;height: 300px"></div>
    </div>
    <div class="box">
      班组
      <div id="teambar" style="width: 700px;height: 300px"></div>
      <div id="teampie" style="width: 900px;height: 300px"></div>
    </div>
    <div class="box">
      楼盘
      <div id="realEstate" style="width: 1600px;height: 400px"></div>
    </div>
    <div class="box">
      故障类型
      <div id="fault" style="width: 1600px;height: 400px"></div>
    </div>
    <div class="box">
      接单人
      <div id="employee" style="width: 1600px;height: 300px"></div>
    </div>
  </div>
</template>

<script>
  import * as echarts from "echarts";
  import {export2Excel} from "@/util";
  import moment from "moment";
  import loginUtil from "@/util/loginUtil";

  export default {
    name: "ElevatorFaults",
    data() {
      return{
        dateOrder:[],
        teamOrder:[],
        faultOrder:[],
        realEstateOrder:[],
        receiverOrder:[],
        excelArr:[],
        filter:{
          maintenanceType:"as_need",
          companyId: loginUtil.getCompanyCode(),
        },
      };
    },
    mounted() {
      this.fetch();
    },
    methods:{
      refetch() {
        this.filter={};
        this.fetch();
      },
      handleTime() {
        if(this.filter.seTime !=null) {
          this.filter.createTimeFrom = this.filter.seTime[0];
          this.filter.createTimeTo = this.filter.seTime[1];
        }else {
          this.filter.createTimeFrom = "";
          this.filter.createTimeTo = "";
        }
      },
      exportExcelData() {
        const title={
          dateTime:"日期",
          dateNum:"工单数",
          teamName:"维保班组",
          teamNum:"工单数",
          realEstateName:"楼盘名称",
          realEstateNum:"工单数",
          faultName:"故障类型",
          faultNum:"工单数",
          receiverName:"接单人",
          receiverNum:"工单数",
        };
        export2Excel(title,this.excelArr,`数据统计-故障统计-${this.excelArr.length}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
      },
      fetch() {
        if (this.filter.date instanceof Array && this.filter.date.length>0) {
          this.filter.curDate  = this.filter.date.map(item=>moment(item).format("YYYY-MM-DD"));
        }else {
          this.filter.curDate=[];
        }
        this.$http.get("/maintenance/repair-order/statistical-data",this.filter).then(res=>{
          this.dateOrder = res.dateOrder;
          const number = 50;
          const teamNumber = 30;
          let count = 0;
          res.teamOrder.sort(function(a,b) {
            return  b.orderNumber - a.orderNumber;
          }).forEach((item,index)=>{
            if (index>=teamNumber) {
              count+=item.orderNumber;
            }
          });
          this.teamOrder = res.teamOrder.sort(function(a,b) {
            return  b.orderNumber - a.orderNumber;
          }).slice(0,teamNumber).concat([{teamName:"其他",orderNumber:count}]);
          this.faultOrder = res.faultOrder.sort(function(a,b) {
            return  b.orderNumber - a.orderNumber;
          });
          // const initNum=data.length;
          // list.forEach((item,index)=>{
          //   if (index<initNum&&index>=initNum-15) {
          //     name.push(item.teamName);
          //     data1.push(item.repairQuantity);
          //     data2.push(item.maintainQuantity);
          //   }
          // });

          this.realEstateOrder = res.realEstateOrder.sort(function(a,b) {
            return  b.orderNumber - a.orderNumber;
          }).slice(0,number);

          console.log(this.realEstateOrder,149);
          this.receiverOrder = res.receiverOrder.sort(function(a,b) {
            return  b.orderNumber - a.orderNumber;
          }).slice(0,number);
          this.teamBar();
          this.dateFault();
          this.teamPie();
          this.fault();
          this.realEstate();
          this.employee();


          count  =1;
          if (this.dateOrder.length>count) {count=this.dateOrder.length;}
          if (this.teamOrder.length>count) {count=this.teamOrder.length;}
          if (this.faultOrder.length>count) {count=this.faultOrder.length;}
          if (this.realEstateOrder.length>count) {count=this.realEstateOrder.length;}
          if (this.receiverOrder.length>count) {count=this.receiverOrder.length;}
          const  arr = [];
          for (let i = 0; i < count; i++) {
            arr.push({
              dateTime:this.dateOrder[i]?.dateTime,
              dateNum:this.dateOrder[i]?.orderNumber,
              teamName:this.teamOrder[i]?.teamName,
              teamNum:this.teamOrder[i]?.orderNumber,
              realEstateName:this.realEstateOrder[i]?.realEstateName,
              realEstateNum:this.realEstateOrder[i]?.orderNumber,
              faultName:this.faultOrder[i]?.faultName,
              faultNum:this.faultOrder[i]?.orderNumber,
              receiverName:this.receiverOrder[i]?.receiverName,
              receiverNum:this.receiverOrder[i]?.orderNumber,
            });
          }
          this.excelArr =arr;

          console.log(arr,72);
        });
      },
      dateFault() {
        const chart = echarts.init(document.getElementById("date"));
        const dateList =  this.dateOrder.map(item=>item.dateTime);
        const dataList =  this.dateOrder.map(item=>{
          if (item.holiday) {
            return  {
              value: item.orderNumber,
              itemStyle: {
                color: "#a90000",
              },
            };
          }else {
            return item.orderNumber;
          }
        });
        const  option = {
          label:{
            show:true,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          xAxis: {
            type: "category",
            data: dateList,
            nameTextStyle:{
              overflow:"breakAll",
            },
            axisLabel: { interval: 0, rotate: 60 },
            axisTick:{alignWithLabel:true},
          },
          yAxis: {
            type: "value",
            alignTicks:true,
          },
          series: [
            {
              data: dataList,
              type: "bar",
            },
          ],
        };
        chart.setOption(option);
      },
      teamBar() {
        const chart = echarts.init(document.getElementById("teambar"));
        const dateList =  this.teamOrder.map(item=>item.teamName);
        const dataList =  this.teamOrder.map(item=>item.orderNumber);
        const  option = {
          label:{
            show:true,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          xAxis: {
            type: "category",
            data: dateList,
            nameTextStyle:{
              overflow:"breakAll",
            },
            axisLabel: { interval: 0, rotate: 60 },
            axisTick:{alignWithLabel:true},
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: dataList,
              type: "bar",
            },
          ],
        };
        chart.setOption(option);
      },
      teamPie() {
        const chart = echarts.init(document.getElementById("teampie"));
        const data = this.teamOrder.map(item=>{return {name:item.teamName,value:item.orderNumber};});
        console.log(data,125);
        const option = {
          label:{
            show:false,
          },
          tooltip: {
            trigger: "item",
          },
          legend: {
            orient: "vertical",
            left: "left",
          },
          series: [
            {
              name: "维修工单",
              type: "pie",
              radius: "80%",
              center: ["80%", "50%"],
              data:data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
              label: {
                show: false,
              },
            },
          ],
        };
        chart.setOption(option);
      },
      realEstate() {
        const chart = echarts.init(document.getElementById("realEstate"));
        const dateList =  this.realEstateOrder.map(item=>item.realEstateName);
        const dataList =  this.realEstateOrder.map(item=>item.orderNumber);
        const  option = {

          tooltip: {
            trigger: "item",
          },
          label:{
            show:true,
          },
          xAxis: {
            type: "category",
            data: dateList,
            nameTextStyle:{
              overflow:"breakAll",
            },
            axisLabel: { interval: 0, rotate: 60 },
            axisTick:{alignWithLabel:true},
          },
          yAxis: {
            type: "value",

          },
          series: [
            {
              data: dataList,
              type: "bar",
            },
          ],
        };
        chart.setOption(option);
      },
      fault() {
        const chart = echarts.init(document.getElementById("fault"));
        const dateList =  this.faultOrder.map(item=>item.faultName);
        const dataList =  this.faultOrder.map(item=>item.orderNumber);
        const  option = {
          label:{
            show:true,
          },
          tooltip: {
            trigger: "item",
          },
          xAxis: {
            type: "category",
            data: dateList,
            nameTextStyle:{
              overflow:"breakAll",
            },
            axisLabel: { interval: 0, rotate: 60 },
            axisTick:{alignWithLabel:true},
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: dataList,
              type: "bar",
            },
          ],
        };
        chart.setOption(option);
      },
      employee() {
        const chart = echarts.init(document.getElementById("employee"));
        const dateList =  this.receiverOrder.map(item=>item.receiverName);
        const dataList =  this.receiverOrder.map(item=>item.orderNumber);
        const  option = {
          label:{
            show:true,
          },
          tooltip: {
            trigger: "item",
          },
          xAxis: {
            type: "category",
            data: dateList,
            nameTextStyle:{
              overflow:"breakAll",
            },
            axisLabel: { interval: 0, rotate: 60 },
            axisTick:{alignWithLabel:true},
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: dataList,
              type: "bar",
            },
          ],
        };
        chart.setOption(option);
      },
    },
  };
</script>

<style scoped>
.box{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
}
.content{
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap:wrap
}
</style>
